import axios from "axios";

export const LOCAL = false;

export let APIENDPOINT_AUTH = 'https://estorya-services-production.azurewebsites.net/api';
if(LOCAL) {
  APIENDPOINT_AUTH = 'https://asia-east1-ace-sector-251421.cloudfunctions.net/estorya';
}

// export const APIENDPOINT = 'https://estorya-api-n5yb9.ondigitalocean.app/api';
export const APIENDPOINT = 'https://estorya-services-production.azurewebsites.net/api';
export const APIENDPOINT_INSIGHTS = 'https://estorya-insights-production.azurewebsites.net/api';
export const axiosInstance = axios.create();

export const ROLES = {
  SCHOOLADMIN: 'Superadmin',
  TEACHER: 'Teacher',
  STUDENT: 'Student'
};

export const SCHOOLID = 1;